import React from "react";
import moment from "moment";
import { Row, Col } from "react-flexbox-grid";
import {
  Status,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormTextarea,
  Button,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSync } from "@fortawesome/free-solid-svg-icons";

import {
  getPrice,
  getInt,
  getIcon,
  getAverage,
  getDifferenceDays,
  getDifferenceMonths,
  sortArray,
} from "../../../utils/general";
import { getRegionFromDepartment } from "../../../utils/region";
import {
  getNegotiationPrice,
  getTimePrice,
  getMileagePrice,
} from "../../../utils/calculation";

import ModalData from "../market-search/ModalData";

import AuthContext from "../../../context/AuthContext";

export default class Vrade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      negotiationCoefficientCustom: null,
      timeCoefficientCustom: null,
      mileageCoefficientCustom: null,
      finalValueCustom: null,
      isModalData: false,
      modalDataIdx: null,
      expertAnalysis: "",
      isExpertAnalysisLock: false,
      name: "price",
      desc: true,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  static contextType = AuthContext;

  handleInputChange = (e, callback = () => {}) => {
    if (this.props.isEdit) {
      const { value, name } = e.target;
      this.setState(
        {
          [name]:
            name === "negotiationCoefficientCustom" && value > 0 ? 0 : value,
        },
        callback
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dataSummary) !==
      JSON.stringify(this.props.dataSummary)
    ) {
      this.setState(
        {
          negotiationCoefficientCustom: this.props.dataSummary
            .negotiationCoefficientCustom
            ? this.props.dataSummary.negotiationCoefficientCustom
            : null,
          timeCoefficientCustom: this.props.dataSummary.timeCoefficientCustom
            ? this.props.dataSummary.timeCoefficientCustom
            : null,
          mileageCoefficientCustom: this.props.dataSummary
            .mileageCoefficientCustom
            ? this.props.dataSummary.mileageCoefficientCustom
            : null,
          finalValueCustom: this.props.dataSummary.finalValueCustom
            ? this.props.dataSummary.finalValueCustom
            : null,
          expertAnalysis: this.props.dataSummary.expertAnalysis
            ? this.props.dataSummary.expertAnalysis
            : null,
          isExpertAnalysisLock: !!this.props.dataSummary.isExpertAnalysisLock,
          name: this.props.dataSummary.name || "price",
          desc: this.props.dataSummary.desc,
        },
        () => {
          this.reloadExpertAnalysis(prevProps);
        }
      );
    } else {
      this.reloadExpertAnalysis(prevProps);
    }
  }

  reloadExpertAnalysis = (prevProps) => {
    const expertAnalysis = this.getExpertAnalysis();

    if (
      !this.state.isExpertAnalysisLock &&
      JSON.stringify(this.props.adsSelect) !==
        JSON.stringify(prevProps.adsSelect) &&
      expertAnalysis !== this.state.expertAnalysis
    ) {
      this.setState(
        {
          expertAnalysis,
        },
        () => {
          this.onUpdateDataSummary();
        }
      );
    }
  };

  getExpertAnalysis = () => {
    const dataAd = this.getDataAd();

    let adsSelect = JSON.parse(JSON.stringify(this.props.adsSelect));

    adsSelect.forEach((ad, idx) => {
      Object.keys(ad).forEach((key) => {
        if (adsSelect && adsSelect[idx]) {
          adsSelect[idx][key] = this.getCorrectValue(
            this.props.dataSelect[ad.id],
            ad,
            key
          );
        }
      });
    });

    let folderAge = null;
    if (
      this.props.dataIdentification &&
      this.props.dataIdentification["Date1MEC"] &&
      this.props.dataIdentification["Date_Sinistre"]
    ) {
      folderAge = getDifferenceMonths(
        moment(this.props.dataIdentification["Date1MEC"][0], "DD/MM/YYYY"),
        moment(this.props.dataIdentification["Date_Sinistre"][0], "DD/MM/YYYY")
      );
    }

    let adsCalculation = adsSelect.map((element, idx) => {
      const delay = getDifferenceDays(
        moment(element.date, "DD/MM/YYYY"),
        moment(this.props.dataSelect[element.id].date)
      );

      adsSelect[idx]["price"] = parseInt(element.price);

      let age = null;
      if (
        this.props.dataIdentification &&
        this.props.dataIdentification["Date_Sinistre"] &&
        this.props.dataIdentification["Date_Sinistre"][0]
      ) {
        age = getDifferenceMonths(
          moment(element["date_circulation"], "MM/YYYY"),
          moment(
            this.props.dataIdentification["Date_Sinistre"][0],
            "DD/MM/YYYY"
          )
        );
      }

      const timePrice = getTimePrice(folderAge, age, element.price, null).price;

      let mileagePrice = null;
      if (
        this.props.dataIdentification &&
        this.props.dataIdentification["Categorie"] &&
        this.props.dataIdentification["Categorie"][0] &&
        this.props.dataIdentification["Kms"] &&
        this.props.dataIdentification["Kms"][0]
      ) {
        mileagePrice = getMileagePrice(
          this.props.dataIdentification["Kms"][0],
          element.mileage,
          age,
          this.props.dataIdentification["Categorie"][0],
          this.props.gender,
          element.price,
          null
        ).price;
      }

      const price = element.price + timePrice + mileagePrice;

      adsSelect[idx]["timeResult"] = getPrice(timePrice);
      adsSelect[idx]["mileageResult"] = getPrice(mileagePrice);
      adsSelect[idx]["priceResult"] = getPrice(price);
      adsSelect[idx]["region"] =
        element.country && element.country.includes("FR")
          ? `
      ${getRegionFromDepartment(element.department)} ${
              element["zipcode"] ? ` - ${element["zipcode"]}` : ""
            }`
          : element.country;
      adsSelect[idx]["delay"] = getDifferenceDays(
        moment(element.date, "DD/MM/YYYY"),
        moment(this.props.dataSelect[element.id].date)
      );
      adsSelect[idx]["comment"] = this.props.dataSelect[element.id].comment;

      return {
        delay,
        age,
        timePrice,
        mileagePrice,
        price,
      };
    });

    const averageMileage = getAverage(adsSelect, "mileage");
    const averageAge = getAverage(adsCalculation, "age");

    const differenceMileage =
      parseInt(
        this.props.dataIdentification["Kms"]
          ? this.props.dataIdentification["Kms"][0]
          : 0
      ) - averageMileage;
    const differenceAge =
      getDifferenceMonths(
        moment(
          this.props.dataIdentification["Date1MEC"]
            ? this.props.dataIdentification["Date1MEC"][0]
            : null,
          "DD/MM/YYYY"
        ),
        moment(
          this.props.dataIdentification["Date_Sinistre"]
            ? this.props.dataIdentification["Date_Sinistre"][0]
            : null,
          "DD/MM/YYYY"
        )
      ) - averageAge;

    let text1 = `- Les prix annoncés des véhicules équivalents sélectionnés sont compris entre ${getPrice(
      dataAd.minPrice
    )} et ${getPrice(dataAd.maxPrice)}`;
    let text2 = `- Les kilométrages des véhicules équivalents sélectionnés sont compris entre ${getInt(
      dataAd.minMileage
    )} km et ${getInt(dataAd.maxMileage)} km`;
    let text3 = `- Les dates de mise en circulation des véhicules équivalents sélectionnés sont comprises entre ${dataAd.minDate} et ${dataAd.maxDate}`;
    let text4 = `- La VRADE estimée tient compte de l'état général (${getPrice(
      this.props.vehicleValue
    )}) et du positionnement du véhicule expertisé comparé aux véhicules équivalents sur le marché : ${
      differenceMileage > 0 ? "+" : ""
    } ${getInt(differenceMileage, 0)} km et ${
      differenceAge > 0 ? "+" : ""
    } ${getInt(differenceAge, 0)} mois.`;

    return `${text1}\n${text2}\n${text3}\n${text4}`;
  };

  onUpdateDataSummary = () => {
    if (this.props.isEdit) {
      const tmpDataSummary = {
        negotiationCoefficientCustom: this.state.negotiationCoefficientCustom,
        timeCoefficientCustom: this.state.timeCoefficientCustom,
        mileageCoefficientCustom: this.state.mileageCoefficientCustom,
        finalValueCustom: this.state.finalValueCustom,
        expertAnalysis: this.state.expertAnalysis,
        isExpertAnalysisLock: this.state.isExpertAnalysisLock,
        name: this.state.name,
        desc: this.state.desc,
      };

      this.props.updateFolder("dataSummary", tmpDataSummary, () => {});
    }
  };

  onClickDeleteAd = (idx) => {
    if (this.props.isEdit) {
      let tmpDataSelect = JSON.parse(JSON.stringify(this.props.dataSelect));

      tmpDataSelect[this.props.adsSelect[idx].id].status = undefined;

      this.props.updateFolder("dataSelect", tmpDataSelect);
    }
  };

  onClickOpenModalData = (e, idx) => {
    if (this.props.isEdit) {
      if (
        !e.target.matches(".not-click") &&
        !e.target.parentNode.matches(".not-click") &&
        !e.target.parentNode.parentNode.matches(".not-click") &&
        !e.target.parentNode.parentNode.parentNode.matches(".not-click") &&
        !e.target.parentNode.parentNode.parentNode.parentNode.matches(
          ".not-click"
        ) &&
        !e.target.parentNode.parentNode.parentNode.parentNode.parentNode.matches(
          ".not-click"
        )
      ) {
        this.setState({
          isLoad: false,
          isModalData: true,
          modalDataIdx: idx,
        });
      }
    }
  };

  updateFolderSelect = (idx, data) => {
    if (this.props.isEdit) {
      let tmpDataSelect = JSON.parse(JSON.stringify(this.props.dataSelect));

      if (tmpDataSelect.hasOwnProperty(this.props.adsSelect[idx].id)) {
        tmpDataSelect[this.props.adsSelect[idx].id] = {
          ...tmpDataSelect[this.props.adsSelect[idx].id],
          ...data,
        };
      } else {
        tmpDataSelect[this.props.adsSelect[idx].id] = data;
      }

      this.props.updateFolder("dataSelect", tmpDataSelect);
    }
  };

  onClickupdateStatusAd = (idx, status) => {
    if (this.props.isEdit) {
      let tmpDataSelect = JSON.parse(JSON.stringify(this.props.dataSelect));

      if (tmpDataSelect.hasOwnProperty(this.props.adsSelect[idx].id)) {
        tmpDataSelect[this.props.adsSelect[idx].id].status = status;
        if (status === true) {
          tmpDataSelect[this.props.adsSelect[idx].id].date = moment();
        }
      } else {
        tmpDataSelect[this.props.adsSelect[idx].id] = {
          status,
          date: status === true ? moment() : undefined,
        };
      }

      if (status === false) {
        this.setState(
          {
            isModalData: false,
            modalDataIdx: null,
          },
          () => this.props.updateFolder("dataSelect", tmpDataSelect)
        );
      }
    }
  };

  getCorrectValue(dataEdit, data, name) {
    if (name === "date" || name === "status") {
      return data[name];
    }

    if (dataEdit[name]) {
      return name === "price" && dataEdit["typePrice"] === "HT"
        ? dataEdit[name] * 1.2
        : dataEdit[name];
    } else {
      return name === "price" && dataEdit["typePrice"] === "HT"
        ? data[name] * 1.2
        : data[name];
    }
  }

  getDataAd = () => {
    let minPrice = null;
    let maxPrice = null;

    let minMileage = null;
    let maxMileage = null;

    let minDate = null;
    let maxDate = null;

    this.props.adsSelect.forEach((ad) => {
      if (minPrice === null || ad.price < minPrice) {
        minPrice = ad.price;
      }
      if (maxPrice === null || ad.price > maxPrice) {
        maxPrice = ad.price;
      }

      if (minMileage === null || ad.mileage < minMileage) {
        minMileage = ad.mileage;
      }
      if (maxMileage === null || ad.mileage > maxMileage) {
        maxMileage = ad.mileage;
      }

      if (
        minDate === null ||
        moment(ad.date_circulation, "MM/YYYY").isBefore(
          moment(minDate, "MM/YYYY")
        )
      ) {
        minDate = ad.date_circulation;
      }
      if (
        maxDate === null ||
        moment(ad.date_circulation, "MM/YYYY").isAfter(
          moment(maxDate, "MM/YYYY")
        )
      ) {
        maxDate = ad.date_circulation;
      }
    });

    return {
      minPrice,
      maxPrice,
      minMileage,
      maxMileage,
      minDate,
      maxDate,
    };
  };

  handleSortClick = (desc, column) => {
    this.sortRef.map((elem, idx) => {
      if (idx !== column) {
        this.sortRef[idx].current.setState({ desc: false, sort: false });
      }

      return null;
    });

    this.setState(
      {
        name: this.sortRef[column].current.props.nameSort,
        desc,
      },
      this.onUpdateDataSummary
    );
  };

  render() {
    let adsSelect = JSON.parse(JSON.stringify(this.props.adsSelect));

    adsSelect.forEach((ad, idx) => {
      Object.keys(ad).forEach((key) => {
        adsSelect[idx][key] = this.getCorrectValue(
          this.props.dataSelect[ad.id],
          ad,
          key
        );
      });
    });

    let folderAge = null;
    if (
      this.props.dataIdentification &&
      this.props.dataIdentification["Date1MEC"] &&
      this.props.dataIdentification["Date_Sinistre"]
    ) {
      folderAge = getDifferenceMonths(
        moment(this.props.dataIdentification["Date1MEC"][0], "DD/MM/YYYY"),
        moment(this.props.dataIdentification["Date_Sinistre"][0], "DD/MM/YYYY")
      );
    }

    let adsCalculation = adsSelect.map((element, idx) => {
      const delay = getDifferenceDays(
        moment(element.date, "DD/MM/YYYY"),
        moment(this.props.dataSelect[element.id].date)
      );

      adsSelect[idx]["price"] = parseInt(element.price);

      let age = getDifferenceMonths(
        moment(element["date_circulation"], "MM/YYYY"),
        moment(this.props.date)
      );

      const timePrice = getTimePrice(folderAge, age, element.price, null).price;

      let mileagePrice = null;
      if (
        this.props.dataIdentification &&
        this.props.dataIdentification["Categorie"] &&
        this.props.dataIdentification["Categorie"][0] &&
        this.props.dataIdentification["Kms"] &&
        this.props.dataIdentification["Kms"][0]
      ) {
        mileagePrice = getMileagePrice(
          this.props.dataIdentification["Kms"][0],
          element.mileage,
          age,
          this.props.dataIdentification["Categorie"][0],
          this.props.gender,
          element.price,
          null
        ).price;
      }

      const price = element.price + timePrice + mileagePrice;

      adsSelect[idx]["timeResult"] = getPrice(timePrice);
      adsSelect[idx]["mileageResult"] = getPrice(mileagePrice);
      adsSelect[idx]["priceResult"] = getPrice(price);
      adsSelect[idx]["region"] = getRegionFromDepartment(element["department"]);
      adsSelect[idx]["delay"] = getDifferenceDays(
        moment(element.date, "DD/MM/YYYY"),
        moment(this.props.dataSelect[element.id].date)
      );
      adsSelect[idx]["comment"] = this.props.dataSelect[element.id].comment;

      return {
        delay,
        age,
        timePrice,
        mileagePrice,
        price,
      };
    });

    const averageMileage = getAverage(adsSelect, "mileage");
    const averagePrice = getAverage(adsSelect, "price");
    const averageDelay = getAverage(adsCalculation, "delay");
    const averageAge = getAverage(adsCalculation, "age");

    /* if this.context.value === 0 */
    const averageTimePrice = getAverage(adsCalculation, "timePrice");
    const averageMileagePrice = getAverage(adsCalculation, "mileagePrice");

    // for MAIF disable auto negotiation

    const isMAIF =
      this.props.dataIdentification &&
      this.props.dataIdentification["Compagnie"] &&
      this.props.dataIdentification["Compagnie"][0] === "MAIF";

    let negotiationResult = getNegotiationPrice(
      averageDelay,
      averagePrice,
      this.state.negotiationCoefficientCustom,
      this.context.negotitationMin,
      this.context.negotitationMax,
      isMAIF
    );

    let timeResult = getTimePrice(
      folderAge,
      averageAge,
      averagePrice,
      this.state.timeCoefficientCustom
    );

    let mileageResult = {};
    if (
      this.props.dataIdentification &&
      this.props.dataIdentification["Categorie"] &&
      this.props.dataIdentification["Categorie"][0] &&
      this.props.dataIdentification["Kms"] &&
      this.props.dataIdentification["Kms"][0]
    ) {
      mileageResult = getMileagePrice(
        this.props.dataIdentification["Kms"][0],
        averageMileage,
        averageAge,
        this.props.dataIdentification["Categorie"][0],
        this.props.gender,
        averagePrice,
        this.state.mileageCoefficientCustom
      );
    }

    const calculatedValue =
      averagePrice +
      negotiationResult.price +
      (this.context.value ? timeResult.price : averageTimePrice) +
      (this.context.value ? mileageResult.price : averageMileagePrice) +
      this.props.vehicleValue;

    const finalValue = this.state.finalValueCustom
      ? parseInt(this.state.finalValueCustom)
      : calculatedValue
      ? parseInt(calculatedValue / 100) * 100
      : 0;

    const averagePriceResult = getAverage(adsCalculation, "price");

    this.data = {
      adsSelect,
      folderAge,
      averageMileage,
      averagePrice,
      averageDelay,
      averageAge,
      averageTimePrice,
      averageMileagePrice,
      negotiationResult,
      timeResult,
      mileageResult,
      calculatedValue,
      averagePriceResult,
      finalValue,
      vehicleValue: this.props.vehicleValue,
      valueType: this.context.value,
      expertAnalysis: this.state.expertAnalysis,
    };

    this.averagePrice = this.context.value
      ? averagePrice
      : getAverage(adsCalculation, "price");

    adsSelect = sortArray(adsSelect, this.state.name, this.state.desc);

    return (
      <>
        <Card
          title="Analyse marché / Valeur de Remplacement À Dire d'Expert"
          className="mt-10 mt-20-xl"
          classNameChildren="overflow-x-auto"
        >
          <Table className="mb-20">
            <Thead>
              <Tr>
                <Th>Photo</Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  ref={this.sortRef[0]}
                  nameSort="title"
                >
                  Titre
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                  ref={this.sortRef[1]}
                  nameSort="finish"
                >
                  Finition
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                  ref={this.sortRef[2]}
                  nameSort="type"
                >
                  Type
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                  ref={this.sortRef[3]}
                  nameSort="year"
                >
                  Année
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 4)}
                  ref={this.sortRef[4]}
                  nameSort="mileage"
                >
                  Kilométrage
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 5)}
                  ref={this.sortRef[5]}
                  nameSort="price"
                >
                  Prix
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 6)}
                  ref={this.sortRef[6]}
                  nameSort="zipcode"
                >
                  Région
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 7)}
                  ref={this.sortRef[7]}
                  nameSort="source"
                >
                  Source
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 8)}
                  ref={this.sortRef[8]}
                  nameSort="delay"
                >
                  Délai parution
                </Th>
                {!this.context.value ? (
                  <>
                    <Th>+/- val. temp. *</Th>
                    <Th>+/- val. km. *</Th>
                    <Th>Prix retenu</Th>
                  </>
                ) : null}
                {!this.props.isPrint ? <Th>Actions</Th> : null}
              </Tr>
            </Thead>
            <Tbody>
              {adsSelect.map((element, idx) => (
                <Tr
                  key={idx}
                  className="cursor-pointer"
                  onClick={(e) => {
                    this.onClickOpenModalData(
                      e,
                      this.props.adsSelect
                        .map((ad, idx) => (ad.id === element.id ? idx : -1))
                        .filter((el) => el >= 0)[0]
                    );
                  }}
                >
                  <Th
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      padding: "0 !important",
                    }}
                  >
                    {element.image ? (
                      <img
                        src={element.image}
                        style={{
                          position: "absolute",
                          width: " 100%",
                          top: "0",
                          bottom: "0",
                          left: " 50%",
                          transform: "translateX(-50%)",
                        }}
                        alt="car"
                      />
                    ) : null}
                  </Th>
                  <Td>
                    {element.status ? (
                      <Status
                        text="désactivée"
                        className="mt-auto mb-auto bg-orange"
                        style={{ marginRight: "6px" }}
                      />
                    ) : null}{" "}
                    {element.type ? (
                      <Status
                        text="pro"
                        className="mt-auto mb-auto"
                        style={{ marginRight: "6px" }}
                      />
                    ) : null}
                    {element.title}
                  </Td>
                  <Td>{element["finish"]}</Td>
                  <Td>{element["type"] || ""}</Td>
                  <Td>{element["date_circulation"]}</Td>
                  <Td>{getInt(element["mileage"])} km</Td>
                  <Td>{getPrice(element["price"])}</Td>
                  <Td>
                    {element.country && element.country.includes("FR")
                      ? `
                            ${getRegionFromDepartment(element.department)} ${
                          element["zipcode"] ? ` - ${element["zipcode"]}` : ""
                        }`
                      : element.country}
                  </Td>
                  <Td>
                    {!element.status ? (
                      <div className="d-flex">
                        <img
                          className="not-click cursor-pointer m-auto"
                          onClick={() => window.open(element.url, "_blank")}
                          alt="icon"
                          height={24}
                          src={getIcon(element.source, element.url)}
                        />
                      </div>
                    ) : null}
                  </Td>
                  <Td>{element.delay} jours</Td>
                  {!this.context.value ? (
                    <>
                      <Td>{getPrice(adsCalculation[idx].timePrice)}</Td>
                      <Td>{getPrice(adsCalculation[idx].mileagePrice)}</Td>
                      <Td>{getPrice(adsCalculation[idx].price)}</Td>
                    </>
                  ) : null}
                  {!this.props.isPrint ? (
                    <Td>
                      <ButtonIcon
                        className="not-click red"
                        small
                        onClick={() => this.onClickDeleteAd(idx)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                    </Td>
                  ) : null}
                </Tr>
              ))}
            </Tbody>
            <Tbody>
              <Tr>
                <Th colSpan={4}>Moyenne</Th>
                <Td className="bg-primary white">
                  {getInt(averageAge, 0)} mois
                </Td>
                <Td className="bg-primary white">
                  {getInt(averageMileage, 0)} km
                </Td>
                <Td className="bg-primary white">
                  {getPrice(averagePrice, 0)}
                </Td>
                <Th colSpan={2}></Th>
                <Td className="bg-primary white">
                  {getInt(averageDelay, 0)} jours
                </Td>
                {!this.context.value ? (
                  <>
                    <Td className="bg-primary white">
                      {getPrice(averageTimePrice, 0)}
                    </Td>
                    <Td className="bg-primary white">
                      {getPrice(averageMileagePrice, 0)}
                    </Td>
                    <Td className="bg-primary white">
                      {getPrice(averagePriceResult, 0)}
                    </Td>
                  </>
                ) : null}
                {!this.props.isPrint ? <Th></Th> : null}
              </Tr>
            </Tbody>
          </Table>
          <Row>
            <Col xs={12} lg={5}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Libellé</Th>
                    <Th>Coefficient</Th>
                    <Th colSpan={2}>Calcul</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td colSpan={2}>Prix moyen marché</Td>
                    <Td colSpan={2} className="bg-primary white">
                      {this.context.value
                        ? getPrice(averagePrice)
                        : getPrice(getAverage(adsCalculation, "price"))}
                    </Td>
                  </Tr>
                  {isMAIF ? null : (
                    <Tr>
                      <Td>Négociation marché *</Td>
                      <Td className="bg-primary white">
                        <div className="d-flex">
                          <input
                            className="input-administrative white"
                            value={
                              this.state.negotiationCoefficientCustom !== null
                                ? this.state.negotiationCoefficientCustom
                                : negotiationResult.coefficient
                            }
                            name="negotiationCoefficientCustom"
                            type="number"
                            onChange={this.handleInputChange}
                            onBlur={this.onUpdateDataSummary}
                          />
                          %
                        </div>
                      </Td>
                      <Td className="bg-primary white">
                        {getPrice(negotiationResult.price)}
                      </Td>
                      <Td>
                        <ButtonIcon
                          className={`not-click ${
                            this.state.negotiationCoefficientCustom !== null
                              ? "red"
                              : "green"
                          } m-auto`}
                          small
                          onClick={() =>
                            this.setState(
                              { negotiationCoefficientCustom: null },
                              this.onUpdateDataSummary
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faSync} />
                        </ButtonIcon>
                      </Td>
                    </Tr>
                  )}
                  {this.context.value ? (
                    <>
                      <Tr>
                        <Td>+/- value temporelle *</Td>
                        <Td className="bg-primary white">
                          <div className="d-flex">
                            <input
                              className="input-administrative white"
                              value={
                                this.state.timeCoefficientCustom !== null
                                  ? this.state.timeCoefficientCustom
                                  : timeResult.coefficient
                                  ? timeResult.coefficient
                                  : 0
                              }
                              name="timeCoefficientCustom"
                              type="number"
                              onChange={this.handleInputChange}
                              onBlur={this.onUpdateDataSummary}
                            />
                            %
                          </div>
                        </Td>
                        <Td className="bg-primary white">
                          {" "}
                          {getPrice(timeResult.price)}
                        </Td>
                        <Td>
                          <ButtonIcon
                            className={`not-click ${
                              this.state.timeCoefficientCustom !== null
                                ? "red"
                                : "green"
                            } m-auto`}
                            small
                            onClick={() =>
                              this.setState(
                                { timeCoefficientCustom: null },
                                this.onUpdateDataSummary
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faSync} />
                          </ButtonIcon>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>+/- value kilométrique *</Td>
                        <Td className="bg-primary white">
                          <div className="d-flex">
                            <input
                              className="input-administrative white"
                              value={
                                this.state.mileageCoefficientCustom !== null
                                  ? this.state.mileageCoefficientCustom
                                  : mileageResult.coefficient
                                  ? mileageResult.coefficient
                                  : 0
                              }
                              name="mileageCoefficientCustom"
                              type="number"
                              onChange={this.handleInputChange}
                              onBlur={this.onUpdateDataSummary}
                            />
                            %
                          </div>
                        </Td>
                        <Td className="bg-primary white">
                          {getPrice(mileageResult.price)}
                        </Td>
                        <Td>
                          <ButtonIcon
                            className={`not-click ${
                              this.state.mileageCoefficientCustom !== null
                                ? "red"
                                : "green"
                            } m-auto`}
                            small
                            onClick={() =>
                              this.setState(
                                { mileageCoefficientCustom: null },
                                this.onUpdateDataSummary
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faSync} />
                          </ButtonIcon>
                        </Td>
                      </Tr>
                    </>
                  ) : null}
                  <Tr>
                    <Td colSpan={2}>Prix moyen marché retenu</Td>
                    <Td colSpan={2} className="bg-primary white">
                      {getPrice(
                        parseInt(
                          (calculatedValue - this.props.vehicleValue) / 10
                        ) * 10
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={2}>+/- value état</Td>
                    <Td colSpan={2} className="bg-primary white">
                      {getPrice(this.props.vehicleValue)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={2}>Valeur calculée</Td>
                    <Td colSpan={2} className="bg-primary white">
                      {getPrice(calculatedValue)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Col>
            <Col xs={12} lg={7} className="d-flex flex-column w-100">
              <div className="d-flex m-auto">
                <div
                  className="p-20"
                  style={{
                    borderRadius: "15px",
                    border: "solid 2px var(--primary-color)",
                  }}
                >
                  <div
                    style={{ fontSize: "20px", color: "var(--primary-color)" }}
                    className="d-flex m-0"
                  >
                    <span className="mt-auto mb-auto mr-5">
                      Valeur retenue :{" "}
                    </span>
                    {!this.props.isPrint ? (
                      <input
                        type="number"
                        name="finalValueCustom"
                        value={parseInt(finalValue)}
                        onChange={this.handleInputChange}
                        onBlur={this.onUpdateDataSummary}
                        className="input-administrative mt-auto mb-auto"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "16px",
                          width: finalValue.toString().length + 3 + "ch",
                        }}
                      />
                    ) : (
                      <span className="mt-auto mb-auto">
                        {getPrice(finalValue)}
                      </span>
                    )}
                    {!this.props.isPrint ? (
                      <>
                        <span className="mt-auto mb-auto mr-5">€</span>
                        <ButtonIcon
                          className={`not-click ${
                            this.state.finalValueCustom !== null
                              ? "red"
                              : "green"
                          } m-auto`}
                          small
                          onClick={() =>
                            this.setState(
                              { finalValueCustom: null },
                              this.onUpdateDataSummary
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faSync} />
                        </ButtonIcon>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              {!this.props.checkAd ? (
                <p style={{ fontSize: "12px" }}>
                  <b>
                    Recherche infructueuse sur plusieurs sources d'annonces, 1
                    seule source retenue
                  </b>
                </p>
              ) : null}
              {!isMAIF ? (
                <>
                  {this.state.timeCoefficientCustom ? (
                    ""
                  ) : (
                    <p style={{ fontSize: "11px" }}>
                      <b>* +/- val. temporelle : </b>plus ou moins-value de 50 €
                      /mois d’écart si le véhicule expertisé a 120 mois (+ ou -
                      1% de 50€ pour chaque mois d’écart par rapport aux 120
                      mois)
                    </p>
                  )}
                  {this.state.mileageCoefficientCustom ? (
                    ""
                  ) : (
                    <p style={{ fontSize: "11px" }}>
                      <b>* +/- val. kilométrique : </b>Application de la table
                      de dépréciation établie par la profession en fonction de
                      la catégorie du véhicule.
                    </p>
                  )}
                  {this.state.negotiationCoefficientCustom ? (
                    ""
                  ) : (
                    <p style={{ fontSize: "11px" }}>
                      <b>* Négociation marché : </b>Prix moyen marché entre 0 et
                      5 jours de parution puis Prix moyen marché -0.5% par jour
                      supplémentaire de parution moyenne.
                    </p>
                  )}
                </>
              ) : null}
            </Col>
          </Row>
          <>
            <Button
              text="Préremplir l'analyse de l'expert"
              className={`w-auto mt-10 ${
                this.state.isExpertAnalysisLock ? "bg-red" : ""
              }`}
              onClick={() => {
                this.setState(
                  {
                    isExpertAnalysisLock: false,
                    expertAnalysis: this.getExpertAnalysis(),
                  },
                  () => this.onUpdateDataSummary()
                );
              }}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>

            <div className="d-flex flex-column" style={{ gap: 10 }}>
              <FormTextarea
                className="mt-10"
                title={"Analyse de l'expert"}
                value={this.state.expertAnalysis}
                name="expertAnalysis"
                onChange={(e) =>
                  this.handleInputChange(e, () =>
                    this.setState({
                      isExpertAnalysisLock: true,
                    })
                  )
                }
                onBlur={this.onUpdateDataSummary}
                rows={6}
              />
              <Button
                onClick={() => {
                  this.setState(
                    {
                      expertAnalysis:
                        this.state.expertAnalysis +
                        (this.state.expertAnalysis ? "\n" : "") +
                        "- Véhicule à faible diffusion sur le marché local, recherche marché étendue au marché national.",
                    },
                    () => this.onUpdateDataSummary()
                  );
                }}
                text="Véhicule à faible diffusion sur le marché local, recherche marché étendue au marché national."
              />
              <Button
                onClick={() => {
                  this.setState(
                    {
                      expertAnalysis:
                        this.state.expertAnalysis +
                        (this.state.expertAnalysis ? "\n" : "") +
                        "- Motorisation à faible diffusion sur le marché de l’occasion, recherche marché étendue à la motorisation la plus proche de la gamme constructeur.",
                    },
                    () => this.onUpdateDataSummary()
                  );
                }}
                text="Motorisation à faible diffusion sur le marché de l’occasion, recherche marché étendue à la motorisation la plus proche de la gamme constructeur."
              />
              <Button
                onClick={() => {
                  this.setState(
                    {
                      expertAnalysis:
                        this.state.expertAnalysis +
                        (this.state.expertAnalysis ? "\n" : "") +
                        "- Finition à faible diffusion, recherche marché étendue à la finition la plus proche de la gamme constructeur.",
                    },
                    () => this.onUpdateDataSummary()
                  );
                }}
                text="Finition à faible diffusion, recherche marché étendue à la finition la plus proche de la gamme constructeur."
              />
            </div>
          </>
        </Card>
        <ModalData
          gender={this.props.gender}
          show={this.state.isModalData}
          ad={
            this.props.adsSelect &&
            this.props.adsSelect.hasOwnProperty(this.state.modalDataIdx)
              ? this.props.adsSelect[this.state.modalDataIdx]
              : {}
          }
          select={
            this.props.dataSelect &&
            this.props.adsSelect &&
            this.props.adsSelect.hasOwnProperty(this.state.modalDataIdx)
              ? this.props.dataSelect[
                  this.props.adsSelect[this.state.modalDataIdx].id
                ]
              : {}
          }
          dataSelect={this.props.dataSelect}
          updateFolderSelect={this.updateFolderSelect}
          idx={this.state.modalDataIdx}
          onClickAddAd={(idx) => this.onClickupdateStatusAd(idx, true)}
          onClickRemoveAd={(idx) => this.onClickupdateStatusAd(idx, false)}
          onClose={() =>
            this.setState({
              isModalData: false,
            })
          }
        />
      </>
    );
  }
}
