import React, { createRef } from "react";
import {
  Nav,
  TabsCustomArrow,
  TabsCustomArrowHeader,
  TabsCustomArrowMenu,
  TabsCustomArrowBody,
  TabsCustomArrowItem,
  Loader,
  FloatActionButton,
  FloatActionButtonItem,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFlag, faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import Save from "../../components/folder/Save";
import AlertSource from "../../components/folder/AlertSource";
import Identification from "./folder/Identification";
import DescriptiveState from "./folder/DescriptiveState";
import MarketSearch from "./folder/MarketSearch";
import Summary from "./folder/Summary";

import ModalPDF from "../../components/home/ModalPDF";

import {
  getFolder,
  updateFolder,
  updateHideImage,
  close,
  exportLacour,
  print,
  updateStatusFolder,
  getPicturesPixTransfer,
  updateHideImagesId,
} from "../../requests/folder";

import {
  getGender,
  getFuel,
  getDifferenceYears,
  checkObject,
} from "../../utils/general";
import { getRegionFromDepartment } from "../../utils/region";

import AuthContext from "../../context/AuthContext";

export default class Folder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      save: null,
      isLoad: true,
      reference: null,
      date: null,
      gender: null,
      fuel: null,
      marketSearchStatus: null,
      descriptiveStateStatus: null,
      // START - data
      dataIdentification: {},
      dataEquipment: {},
      dataPart: {},
      extendDataPart: {},
      dataComment: {},
      dataAdministrative: null,
      dataGuarding: {},
      dataSignature: {},
      dataSelect: {},
      dataSummary: {},
      dataFilters: null,
      dataPixTransfer: [],
      dataHistovec: {},
      dataElectric: {},
      hideImage: 0,
      hideImagesId: [],

      // END - data
      // START - tabs
      tabsId: 0,
      tabsIdArray: [
        { disabled: false, title: "1. Données du véhicule" },
        { disabled: true, title: "2. État descriptif du véhicule" },
        { disabled: true, title: "3. Recherche de véhicules" },
        { disabled: true, title: "4. Étude de marché" },
      ],
      // END - tabs
      isModalPreview: false,
      modalPreviewBase64: "",
      titlePreview: "",
    };

    this.identificationCMPRef = createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getFolder();
    this.getPicturesPixTransfer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.state.dataIdentification) !==
      JSON.stringify(prevState.dataIdentification)
    ) {
      this.setDataFilters();
      this.onUpdateStatusSave(null, () => {});
    }
  }

  getPicturesPixTransfer() {
    getPicturesPixTransfer(this.props.match.params.id, (result) => {
      if (result.success) {
        this.setState({ dataPixTransfer: result.data });
      } else {
        this.setState({ dataPixTransfer: [] });
      }
    });
  }

  getFolder = () => {
    getFolder(this.props.match.params.id, (result) => {
      if (result.success) {
        this.setState(
          {
            isLoad: false,
            reference: result.data.reference,
            date: result.data.date,
            marketSearchStatus: result.data.market_search_status,
            descriptiveStateStatus: result.data.descriptive_state_status,
            dataIdentification: checkObject(result.data.data_identification),
            dataEquipment: checkObject(result.data.data_equipment),
            dataPart: checkObject(result.data.data_part),
            extendDataPart: checkObject(result.data.extend_data_part),
            dataComment: result.data.data_comment,
            dataAdministrative: result.data.data_administrative
              ? checkObject(result.data.data_administrative)
              : { empty: true },
            dataGuarding: checkObject(result.data.data_guarding),
            dataSignature: checkObject(result.data.data_signature),
            dataSelect: checkObject(result.data.data_select),
            dataSummary: checkObject(result.data.data_summary),
            dataHistovec: checkObject(result.data.data_histovec),
            dataElectric: checkObject(result.data.data_electric),
            hideImage: result.data.hide_image,
            hideImagesId: result.data.hide_images_id,
          },
          () => {
            /* START - set default user signature */
            let tmpDataSignature = this.state.dataSignature;
            let isUpdate = false;

            if (
              tmpDataSignature &&
              !tmpDataSignature.expert &&
              this.context.signature
            ) {
              tmpDataSignature.expert = this.context.signature;
              isUpdate = true;
            } else if (!tmpDataSignature && this.context.signature) {
              tmpDataSignature = {
                expert: this.context.signature,
              };
              isUpdate = true;
            }

            if (isUpdate && result.data.descriptive_state_status !== 2) {
              this.updateFolder("dataSignature", tmpDataSignature);
            }
            /* END - set default user signature */
          }
        );
      } else {
        alert("Une erreur est survenue");
        this.props.history.push("/home");
      }
    });
  };

  setDataFilters = () => {
    let tmpDataFilters = null;
    const dataIdentification = this.state.dataIdentification;
    let tmpGender = null;
    let tmpFuel = null;
    if (dataIdentification && dataIdentification["Constructeur"]) {
      tmpDataFilters = {
        brand: dataIdentification["Constructeur"][0],
        model: dataIdentification["Modele"][0],
        search: dataIdentification["Finition"]
          ? dataIdentification["Finition"][0]
          : null,
        fuel: getFuel(dataIdentification["Energie"][0]),
        year: dataIdentification["Annee_Modele"][0],
        mileage: dataIdentification["Kms"][0],
        region: dataIdentification["Code_Postal"]
          ? getRegionFromDepartment(
              dataIdentification["Code_Postal"][0]
                ? dataIdentification["Code_Postal"][0].substring(0, 2)
                : null
            )
          : null,
      };

      tmpGender = getGender(
        dataIdentification["Genre"][0],
        dataIdentification["Carrosserie"][0]
      );
      tmpFuel = getFuel(dataIdentification["Energie"][0]);
    }

    this.setState({
      gender: tmpGender,
      fuel: tmpFuel,
      dataFilters: tmpDataFilters,
    });
  };

  onUpdateStatusSave = (status, callback) => {
    let tmpTabsIdArray = this.state.tabsIdArray;

    if (this.checkDataIdentification(0)) {
      tmpTabsIdArray[1].disabled = this.state.descriptiveStateStatus === 2;
    } else {
      tmpTabsIdArray[1].disabled = true;
    }

    if (this.checkDataIdentification(1)) {
      tmpTabsIdArray[2].disabled = this.state.marketSearchStatus === 2;
      tmpTabsIdArray[3].disabled = this.state.marketSearchStatus === 2;
    } else {
      tmpTabsIdArray[2].disabled = true;
      tmpTabsIdArray[3].disabled = true;
    }

    this.setState(
      {
        tabsIdArray: tmpTabsIdArray,
        save: status !== null ? status : this.state.save,
      },
      callback
    );
  };

  checkSource = () => {
    if (!this.refAlertSource || !this.refAlertSource.checkSource()) {
      if (
        this.state.dataIdentification &&
        this.state.dataIdentification["Compagnie"] &&
        ["MAIF", "MATMUT", "ALLIANZ"].includes(
          this.state.dataIdentification["Compagnie"][0]
        )
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  checkDataIdentification = (type, isPrint = false) => {
    let data = [];

    if (this.identificationCMPRef && this.identificationCMPRef.current) {
      data.push(
        ...this.identificationCMPRef.current.inputAdministrativeIdentification.map(
          (element) => {
            if (type === 0 && element.required1 && element.print1 && !isPrint) {
              return true;
            } else if (
              ((type === 0 && element.required1) ||
                (type === 1 && element.required2)) &&
              this.state.dataIdentification
            ) {
              if (this.state.dataIdentification.hasOwnProperty(element.value)) {
                if (this.state.dataIdentification[element.value][0])
                  return true;
              }
            } else if (type === 0 && !element.required1) {
              return true;
            } else if (type === 1 && !element.required2) {
              return true;
            }
            return false;
          }
        )
      );

      data.push(
        ...this.identificationCMPRef.current.inputVehicleIdentification.map(
          (element) => {
            if (type === 0 && element.required1 && element.print1 && !isPrint) {
              return true;
            } else if (
              ((type === 0 && element.required1) ||
                (type === 1 && element.required2)) &&
              this.state.dataIdentification
            ) {
              if (this.state.dataIdentification.hasOwnProperty(element.value)) {
                if (this.state.dataIdentification[element.value][0])
                  return true;
              }
            } else if (type === 0 && !element.required1) {
              return true;
            } else if (type === 1 && !element.required2) {
              return true;
            }
            return false;
          }
        )
      );
    } else {
      data.push(false);
    }

    if (data.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  onTabsChange = (key) => {
    if (!this.state.tabsIdArray[key].disabled) {
      this.setState({
        tabsId: key,
      });
    } else if ([1].includes(key) && this.state.descriptiveStateStatus === 2) {
      if (
        window.confirm("Êtes-vous sûr de vouloir réouvrir l'état descriptif")
      ) {
        updateStatusFolder(this.props.match.params.id, 0, 1, (result) => {
          if (result.success) {
            this.setState(
              {
                tabsId: key,
                descriptiveStateStatus: 1,
              },
              () => this.onUpdateStatusSave(null, () => {})
            );
          } else {
            alert("Une erreur est survenue");
          }
        });
      } else {
        this.setState({
          tabsId: key,
        });
      }
    } else if ([2, 3].includes(key) && this.state.marketSearchStatus === 2) {
      if (
        window.confirm("Êtes-vous sûr de vouloir réouvrir l'étude de marché")
      ) {
        updateStatusFolder(this.props.match.params.id, 1, 1, (result) => {
          if (result.success) {
            this.setState(
              {
                tabsId: key,
                marketSearchStatus: 1,
              },
              () => this.onUpdateStatusSave(null, () => {})
            );
          } else {
            alert("Une erreur est survenue");
          }
        });
      } else {
        this.setState({
          tabsId: key,
        });
      }
    }
  };

  updateFolder = (type, data, callback = () => {}, other) => {
    if (
      (type === "dataAdministrative" &&
        this.state.marketSearchdescriptiveStateStatusStatus !== 2) ||
      type !== "dataAdministrative"
    ) {
      this.onUpdateStatusSave(1, () => {
        this.setState({ [type]: data }, () => {
          if (type === "dataIdentification") {
            this.setDataFilters();
          }

          updateFolder(
            this.props.match.params.id,
            this.state.reference,
            type,
            data,
            (result) => {
              if (result.success) {
                this.onUpdateStatusSave(2, callback);
              } else {
                this.onUpdateStatusSave(0, callback);
              }
            },
            other
          );
        });
      });
    }
  };

  updateHideImage = () => {
    const hideImage = !this.state.hideImage;

    updateHideImage(this.props.match.params.id, hideImage, (result) => {
      if (result.success) {
        this.setState({ hideImage });
      } else {
        alert("Une erreur est survenue");
      }
    });
  };

  updateHideImagesId = (id) => {
    let hideImagesId = [];

    if (this.state.hideImagesId.includes(id)) {
      hideImagesId = this.state.hideImagesId.filter((hide) => hide !== id);
    } else {
      hideImagesId = [...this.state.hideImagesId, id];
    }

    updateHideImagesId(this.props.match.params.id, hideImagesId, (result) => {
      if (result.success) {
        this.setState({ hideImagesId });
      } else {
        alert("Une erreur est survenue");
      }
    });
  };

  checkTechnicalInspection = () => {
    let result = {
      isValid: false,
      isExist: false,
    };

    if (
      this.state.gender !== 1 &&
      this.state.dataIdentification &&
      this.state.dataIdentification["Date_Sinistre"] &&
      this.state.dataIdentification["Date_Sinistre"][0] !== "NR" &&
      this.state.dataIdentification["Date1MEC"] &&
      this.state.dataIdentification["Date1MEC"][0] !== "NR" &&
      moment(
        this.state.dataIdentification["DateDernierCT"][0],
        "DD/MM/YYYY"
      ).isValid()
    ) {
      result.isExist =
        getDifferenceYears(
          moment(this.state.dataIdentification["Date1MEC"][0], "DD/MM/YYYY"),
          moment(
            this.state.dataIdentification["Date_Sinistre"][0],
            "DD/MM/YYYY"
          )
        ) >= 4;

      if (
        this.state.dataIdentification["DateDernierCT"] &&
        this.state.dataIdentification["DateDernierCT"][0] !== "NR"
      ) {
        result.isValid = moment(
          this.state.dataIdentification["DateDernierCT"][0],
          "DD/MM/YYYY"
        ).isAfter(
          moment(
            this.state.dataIdentification["Date_Sinistre"][0],
            "DD/MM/YYYY"
          )
        );
      }
    }

    return result;
  };

  close = (type, result) => {
    if (result.success) {
      close(
        this.props.match.params.id,
        this.state.reference,
        type,
        this.state.date,
        result.data,
        this.state.dataPixTransfer,
        this.state.hideImagesId,
        (resultPrint) => {
          if (resultPrint.success) {
            if (this.state.tabsId === 1) {
              this.setState(
                { isLoad: false, descriptiveStateStatus: 2, tabsId: 0 },
                () => this.onUpdateStatusSave(null, () => {})
              );
            } else {
              this.setState(
                { isLoad: false, marketSearchStatus: 2, tabsId: 0 },
                () => this.onUpdateStatusSave(null, () => {})
              );
            }
          } else {
            this.setState({ isLoad: false }, () =>
              alert("Une erreur est survenue lors de la clôture")
            );
          }
        }
      );
    } else {
      this.setState({ isLoad: false }, () =>
        alert("Une erreur est survenue lors de la clôture")
      );
    }
  };

  exportLacour = (type, result) => {
    if (result.success) {
      exportLacour(
        this.props.match.params.id,
        this.state.reference,
        type,
        this.state.date,
        result.data,
        this.state.dataPixTransfer,
        this.state.hideImagesId,
        (resultPrint) => {
          if (resultPrint.success) {
            if (this.state.tabsId === 1) {
              this.setState({ isLoad: false });
            } else {
              this.setState({ isLoad: false });
            }
          } else {
            this.setState({ isLoad: false }, () =>
              alert("Une erreur est survenue lors de l'export")
            );
          }
        }
      );
    } else {
      this.setState({ isLoad: false }, () =>
        alert("Une erreur est survenue lors de l'export")
      );
    }
  };

  print = (type, result) => {
    if (result.success) {
      print(
        this.props.match.params.id,
        this.state.reference,
        type,
        this.state.date,
        result.data,
        this.state.dataPixTransfer,
        this.state.hideImagesId,
        (resultPrint) => {
          if (resultPrint.success) {
            this.setState(
              {
                isModalPreview: true,
                modalPreviewBase64: resultPrint.file,
                titlePreview:
                  type === 0
                    ? `État descriptif ${this.state.reference}.pdf`
                    : `Étude de marché ${this.state.reference} - ${resultPrint.finalValue} EUR TTC.pdf`,
              },
              () => {
                this.setState({ isLoad: false });
              }
            );
          } else {
            this.setState({ isLoad: false }, () =>
              alert("Une erreur est survenue lors de l'impression")
            );
          }
        }
      );
    } else {
      this.setState({ isLoad: false }, () =>
        alert("Une erreur est survenue lors de l'impression")
      );
    }
  };

  handlePrintClick = (type) => {
    if (
      this.checkDataIdentification(this.state.tabsId === 1 ? 0 : 1, true) ===
      false
    ) {
      alert(
        "Merci de renseigner les champs obligatoires (couleurs rouge et orange)"
      );
      return;
    }

    if (
      this.state.tabsId === 3 &&
      this.state.dataIdentification &&
      this.state.dataIdentification["Compagnie"] &&
      ["AXA"].includes(this.state.dataIdentification["Compagnie"][0])
    ) {
      const countAd = Object.keys(this.state.dataSelect)
        .map((key) => key)
        .filter((key) => this.state.dataSelect[key].status).length;

      if (countAd < 5) {
        alert("AXA impose la sélection de 5 annonces minimum");
        return;
      }
    }

    this.setState(
      {
        isLoad: true,
      },
      () => {
        if (this.state.tabsId === 3) {
          this.summaryRef.handlePrintClick((result) => {
            if (type === 0) {
              this.close(1, result);
            } else if (type === 1) {
              this.exportLacour(1, result);
            } else if (type === 2) {
              this.print(1, result);
            }
          });
        } else {
          this.descriptiveStateRef.handlePrintClick((result) => {
            if (type === 0) {
              this.close(0, result);
            } else if (type === 1) {
              this.exportLacour(0, result);
            } else if (type === 2) {
              this.print(0, result);
            }
          });
        }
      }
    );
  };

  render() {
    const resultCheckTechnicalInspection = this.checkTechnicalInspection();

    return (
      <>
        <Nav
          title={`Dossier n°${this.state.reference}`}
          className="mb-10 mb-30-xl"
          back
          onBack={() => this.props.history.push("/home")}
        >
          <AlertSource
            ref={(ref) => (this.refAlertSource = ref)}
            onUpdateStatusSave={() => this.onUpdateStatusSave(null, () => {})}
            dataSelect={this.state.dataSelect}
            marketSearchStatus={this.state.marketSearchStatus}
            dataIdentification={this.state.dataIdentification}
          />
          <Save save={this.state.save} />
        </Nav>
        <TabsCustomArrow>
          <TabsCustomArrowHeader className="m-0">
            {this.state.tabsIdArray.map((element, key) => (
              <TabsCustomArrowMenu
                key={key}
                title={element.title}
                disabled={element.disabled}
                active={this.state.tabsId === key}
                onClick={(e) => this.onTabsChange(key)}
              />
            ))}
          </TabsCustomArrowHeader>
          <TabsCustomArrowBody noCard>
            <TabsCustomArrowItem active={this.state.tabsId === 0}>
              <Identification
                ref={this.identificationCMPRef}
                dataIdentification={this.state.dataIdentification}
                updateFolder={this.updateFolder}
              />
            </TabsCustomArrowItem>
            <TabsCustomArrowItem active={this.state.tabsId === 1}>
              <DescriptiveState
                ref={(ref) => {
                  this.descriptiveStateRef = ref;
                }}
                id={this.props.match.params.id}
                gender={this.state.gender}
                fuel={this.state.fuel}
                isTechnicalInspectionExist={
                  resultCheckTechnicalInspection.isExist
                }
                isTechnicalInspectionValid={
                  resultCheckTechnicalInspection.isValid
                }
                dataIdentification={this.state.dataIdentification}
                dataPart={this.state.dataPart}
                extendDataPart={this.state.extendDataPart}
                dataComment={this.state.dataComment}
                dataEquipment={this.state.dataEquipment}
                dataGuarding={this.state.dataGuarding}
                dataSignature={this.state.dataSignature}
                dataPixTransfer={this.state.dataPixTransfer}
                dataHistovec={this.state.dataHistovec}
                dataElectric={this.state.dataElectric}
                updateFolder={this.updateFolder}
                isClosed={this.state.descriptiveStateStatus === 2}
                hideImage={this.state.hideImage}
                updateHideImage={this.updateHideImage}
                hideImagesId={this.state.hideImagesId}
                updateHideImagesId={this.updateHideImagesId}
              />
            </TabsCustomArrowItem>
            <TabsCustomArrowItem active={this.state.tabsId === 2}>
              <MarketSearch
                id={this.props.match.params.id}
                gender={this.state.gender}
                dataFilters={this.state.dataFilters}
                dataSelect={this.state.dataSelect}
                updateFolder={this.updateFolder}
                isClosed={this.state.marketSearchStatus === 2}
                dataIdentification={this.state.dataIdentification}
              />
            </TabsCustomArrowItem>
            <TabsCustomArrowItem active={this.state.tabsId === 3}>
              <Summary
                ref={(ref) => {
                  this.summaryRef = ref;
                }}
                id={this.props.match.params.id}
                date={this.state.date}
                gender={this.state.gender}
                fuel={this.state.fuel}
                isTechnicalInspectionExist={
                  resultCheckTechnicalInspection.isExist
                }
                isTechnicalInspectionValid={
                  resultCheckTechnicalInspection.isValid
                }
                dataIdentification={this.state.dataIdentification}
                dataPart={this.state.dataPart}
                extendDataPart={this.state.extendDataPart}
                dataComment={this.state.dataComment}
                dataEquipment={this.state.dataEquipment}
                dataSelect={this.state.dataSelect}
                dataAdministrative={this.state.dataAdministrative}
                dataSummary={this.state.dataSummary}
                dataPixTransfer={this.state.dataPixTransfer}
                updateFolder={this.updateFolder}
                checkAd={this.checkSource()}
                isClosed={this.state.marketSearchStatus === 2}
                tabsId={this.state.tabsId}
                hideImage={this.state.hideImage}
                updateHideImage={this.updateHideImage}
                hideImagesId={this.state.hideImagesId}
                updateHideImagesId={this.updateHideImagesId}
                dataHistovec={this.state.dataHistovec}
                dataElectric={this.state.dataElectric}
              />
            </TabsCustomArrowItem>
          </TabsCustomArrowBody>
        </TabsCustomArrow>
        {[1, 3].includes(this.state.tabsId) ? (
          <FloatActionButton icon={<FontAwesomeIcon icon={faBars} />}>
            <FloatActionButtonItem
              className="bg-red white"
              icon={<FontAwesomeIcon icon={faFlag} />}
              text={"Exporter + Clôturer"}
              onClick={() => this.handlePrintClick(0)}
            />
            <FloatActionButtonItem
              className="bg-orange white"
              icon={<FontAwesomeIcon icon={faPrint} />}
              text={"Imprimer"}
              onClick={() => this.handlePrintClick(2)}
            />
          </FloatActionButton>
        ) : null}
        <ModalPDF
          onClose={() =>
            this.setState({
              isModalPreview: false,
              titlePreview: "",
            })
          }
          base64={this.state.modalPreviewBase64}
          show={this.state.isModalPreview}
          name={this.state.titlePreview}
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
