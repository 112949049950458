import axiosConfig from "../utils/axiosConfig";

const getFolder = (id, callback) => {
  axiosConfig("/folder/get-folder", {
    data: {
      id,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateFolder = (id, reference, type, data, callback, other = {}) => {
  axiosConfig("/folder/update-folder", {
    data: {
      id,
      reference,
      type,
      data,
      other,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateHideImage = (id, hideImage, callback) => {
  axiosConfig("/folder/update-hide-image", {
    data: {
      id,
      hideImage,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateHideImagesId = (id, hideImagesId, callback) => {
  axiosConfig("/folder/update-hide-images-id", {
    data: {
      id,
      hideImagesId,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const close = (
  id,
  reference,
  type,
  date,
  data,
  dataPixTransfer,
  hideImagesId = [],
  callback
) => {
  axiosConfig("/folder/close", {
    data: {
      id,
      reference,
      type,
      date,
      data,
      hideImagesId,
      dataPixTransfer,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const exportLacour = (
  id,
  reference,
  type,
  date,
  data,
  dataPixTransfer,
  hideImagesId = [],
  callback
) => {
  axiosConfig("/folder/export-lacour", {
    data: {
      id,
      reference,
      type,
      date,
      data,
      hideImagesId,
      dataPixTransfer,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const print = (
  id,
  reference,
  type,
  date,
  data,
  dataPixTransfer,
  hideImagesId = [],
  callback
) => {
  axiosConfig("/folder/print", {
    data: {
      id,
      reference,
      type,
      date,
      data,
      dataPixTransfer,
      hideImagesId,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateStatusFolder = (id, type, value, callback) => {
  axiosConfig("/folder/update-status-folder", {
    data: {
      id,
      type,
      value,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getPicturesPixTransfer = (id, callback) => {
  axiosConfig("/folder/get-pictures-pixtransfer", {
    data: {
      id,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getReportHistovec = (data, callback) => {
  axiosConfig("folder/get-report-histovec", {
    data,
  })
    .then((result) => {
      callback(result.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

const getExternalPicture = (id, callback) => {
  axiosConfig("/folder/get-external-picture", {
    data: {
      id,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  getFolder,
  updateFolder,
  updateHideImage,
  updateHideImagesId,
  close,
  exportLacour,
  print,
  updateStatusFolder,
  getPicturesPixTransfer,
  getReportHistovec,
  getExternalPicture,
};
